* {
  box-sizing: border-box;
}

html {
  background-color: $white;
}

body {
  color: $text-color;
  padding-top: 116px;
  position: relative;

  &::after {
    background-image: linear-gradient(
    to right,
    $border-blue 61%,
    $border-yellow 61%,
    $border-yellow 74%,
    $border-red 74%,
    $border-red 87%,
    $border-green 87%
    );
    background-position: top;
    background-repeat: no-repeat;
    background-size: 1170px 3px;
    content: '';
    height: 3px;
    position: absolute;
    top: 116px;
    width: 100%;

    @media #{$tablet} {
      background-size: calc(100% - 30px) 3px;
      top: 192px;
    }

    @media #{$smartphone} {
      background-image: none;
    }
  }

  @media #{$tablet} {
    padding-top: 192px;
  }

  @media #{$smartphone} {
    padding-top: 360px;
  }
}

main,
section {
  margin-bottom: 30px;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
}

ol,
ul,
p,
dl {
  margin-bottom: 0;
  margin-top: 0;
}

dd {
  margin-left: 0;
}

select {
  appearance: none;
  background-color: darken($white, .1);
  background-image: url('#{$images}/select_arrows.svg');
  background-position: calc(100% - 5px);
  background-repeat: no-repeat;
  background-size: 6px;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 15px;
}

input {
  border: 1px solid $grey-border;
  border-radius: 9px;
  font-size: .75rem;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
}

b {
  font-weight: 700;
}

fieldset {
  border: none;
  padding: 0;
}

hr {
  border-bottom: thin solid $grey;
  color: transparent;
  width: 100%;
}

.no-display,
.hide {
  display: none;
}

.text-center {
  text-align: center;
}

.clearfix {
  clear: both;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: $container-max-width;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.body--scrolled {
  @media #{$smartphone} {
    padding-top: 170px;
  }
}
