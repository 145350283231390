.button {
  @include button-color-scheme($green);

  border-radius: 5px;
  cursor: pointer;
  font-size: .8125rem;
  padding: 6px 15px;
  text-align: center;
  text-transform: uppercase;
  transition-duration: .25s;

  &:active {
    transform: scale(.9);
  }

  &:disabled {
    opacity: .75;
  }

  &--outline {
    @include button-color-scheme-outline(darken($grey, 30%));
  }

  &--details {
    @include button-color-scheme-outline(darken($grey, 30%));
  }

  &--big {
    align-items: center;
    border-radius: 10px;
    display: flex;
    font-size: 1.125rem;
    justify-content: center;
    min-height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    i {
      margin-right: 15px;
    }
  }
}

a.button {
  display: inline-block;

  &:hover,
  &:focus {
    color: $white;
  }
}

.btn-remove {
  color: $text-color;
  font-size: 1rem;
  padding: 2px;
  transition: .25s color;

  &:hover {
    color: $blue;
  }
}

.buttons-set {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  position: relative;

  .back-link {
    color: $text-color;
    font-size: .75rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .required {
    bottom: 100%;
    left: 0;
    position: absolute;
  }
}
