.products-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  @media #{$smartphone} {
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 4fr));
  }

  .item img {
    width: 100%;
  }
}
