@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
.loading-container {
  padding-bottom: 100px;
  padding-top: 100px;
  text-align: center; }
  .loading-container .loading-container__circle {
    animation: spin 1s infinite linear;
    border: 3px solid transparent;
    border-bottom-color: #1e72c5;
    border-radius: 50%;
    display: inline-block;
    height: 75px;
    margin: 10px;
    transition: color 1s, border-radius 3s;
    width: 75px; }
    .loading-container .loading-container__circle--error {
      animation: none;
      border-bottom-color: #1e72c5;
      border-radius: 0; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

* {
  box-sizing: border-box; }

html {
  background-color: #fff; }

body {
  color: #818285;
  padding-top: 116px;
  position: relative; }
  body::after {
    background-image: linear-gradient(to right, #1e72c5 61%, #ffc81f 61%, #ffc81f 74%, #e70029 74%, #e70029 87%, #00c551 87%);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 1170px 3px;
    content: '';
    height: 3px;
    position: absolute;
    top: 116px;
    width: 100%; }
    @media (min-width: 768px) and (max-width: 1199px) {
      body::after {
        background-size: calc(100% - 30px) 3px;
        top: 192px; } }
    @media (max-width: 767px) {
      body::after {
        background-image: none; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    body {
      padding-top: 192px; } }
  @media (max-width: 767px) {
    body {
      padding-top: 360px; } }

main,
section {
  margin-bottom: 30px; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

img {
  max-width: 100%; }

ol,
ul,
p,
dl {
  margin-bottom: 0;
  margin-top: 0; }

dd {
  margin-left: 0; }

select {
  appearance: none;
  background-color: white;
  background-image: url("../images//select_arrows.svg");
  background-position: calc(100% - 5px);
  background-repeat: no-repeat;
  background-size: 6px;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 15px; }

input {
  border: 1px solid rgba(129, 130, 133, 0.25);
  border-radius: 9px;
  font-size: .75rem; }

ol,
ul {
  list-style: none;
  padding-left: 0; }

b {
  font-weight: 700; }

fieldset {
  border: none;
  padding: 0; }

hr {
  border-bottom: thin solid #eee;
  color: transparent;
  width: 100%; }

.no-display,
.hide {
  display: none; }

.text-center {
  text-align: center; }

.clearfix {
  clear: both; }

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

@media (max-width: 767px) {
  .body--scrolled {
    padding-top: 170px; } }

.breadcrumbs {
  font-size: .875rem;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: left; }
  .breadcrumbs,
  .breadcrumbs strong {
    font-weight: bold;
    color: #3a3a3b; }
  .breadcrumbs__list, .breadcrumbs__item {
    display: inline-block; }
  .breadcrumbs__item:not(:last-of-type)::after {
    content: '>';
    margin-left: 15px;
    margin-right: 15px; }

.button {
  background-color: #00c551;
  border: solid 1px #00c551;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: .8125rem;
  padding: 6px 15px;
  text-align: center;
  text-transform: uppercase;
  transition-duration: .25s; }
  .button:hover {
    background-color: #00923c;
    border-color: #00923c; }
  .button:active {
    transform: scale(0.9); }
  .button:disabled {
    opacity: .75; }
  .button--outline {
    background-color: #fff;
    border: solid 1px #a2a2a2;
    color: #a2a2a2; }
    .button--outline:hover {
      background-color: #a2a2a2;
      border-color: #a2a2a2;
      color: #fff; }
  .button--details {
    background-color: #fff;
    border: solid 1px #a2a2a2;
    color: #a2a2a2; }
    .button--details:hover {
      background-color: #a2a2a2;
      border-color: #a2a2a2;
      color: #fff; }
  .button--big {
    align-items: center;
    border-radius: 10px;
    display: flex;
    font-size: 1.125rem;
    justify-content: center;
    min-height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%; }
    .button--big i {
      margin-right: 15px; }

a.button {
  display: inline-block; }
  a.button:hover, a.button:focus {
    color: #fff; }

.btn-remove {
  color: #818285;
  font-size: 1rem;
  padding: 2px;
  transition: .25s color; }
  .btn-remove:hover {
    color: #1e72c5; }

.buttons-set {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  position: relative; }
  .buttons-set .back-link {
    color: #818285;
    font-size: .75rem;
    font-weight: bold;
    text-transform: uppercase; }
  .buttons-set .required {
    bottom: 100%;
    left: 0;
    position: absolute; }

.products-grid.owl-carousel {
  position: relative; }
  .products-grid.owl-carousel .owl-buttons {
    position: absolute;
    right: 0;
    text-align: center;
    top: -4rem; }
    .products-grid.owl-carousel .owl-buttons .owl-next,
    .products-grid.owl-carousel .owl-buttons .owl-prev {
      background-color: #a2a2a2;
      border-radius: 50%;
      color: #fff;
      display: inline-block;
      font-size: 1rem;
      line-height: 25px;
      transition: color .25s;
      width: 25px;
      height: 25px; }
      .products-grid.owl-carousel .owl-buttons .owl-next:hover,
      .products-grid.owl-carousel .owl-buttons .owl-prev:hover {
        background-color: #00c551; }
    .products-grid.owl-carousel .owl-buttons .owl-prev {
      margin-right: 2px; }
    .products-grid.owl-carousel .owl-buttons .owl-next {
      margin-left: 2px; }
  .products-grid.owl-carousel .owl-item {
    margin-bottom: 15px;
    padding-left: 1px;
    padding-right: 5px; }

.columns {
  display: grid;
  grid-gap: 30px; }
  @media (min-width: 1200px) {
    .columns {
      grid-auto-flow: column;
      grid-auto-columns: 1fr; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .columns {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns {
      grid-template-columns: 1fr; } }
  .columns--bordered > div:not(:last-of-type) {
    position: relative; }
    .columns--bordered > div:not(:last-of-type)::after {
      position: absolute;
      content: ''; }
    @media (min-width: 768px) and (max-width: 1199px), (min-width: 1200px) {
      .columns--bordered > div:not(:last-of-type)::after {
        top: 0;
        border-left: solid 1px #eee;
        height: 100%;
        left: calc(100% + 15px); } }
    @media (max-width: 767px) {
      .columns--bordered > div:not(:last-of-type)::after {
        border-top: solid 1px #eee;
        width: 100%;
        left: 0;
        top: calc(100% + 15px); } }
  @media (min-width: 768px) and (max-width: 1199px) and (orientation: landscape), (min-width: 1200px) {
    .columns--has-sidebar {
      grid-template-columns: 1fr 3fr; } }
  @media (max-width: 767px), (min-width: 768px) and (max-width: 1199px) and (orientation: portrait) {
    .columns--has-sidebar {
      grid-template-columns: 1fr; } }

.map_banners .title {
  color: #1e72c5;
  font-size: 2rem;
  font-weight: 400; }
  @media (max-width: 767px) {
    .map_banners .title {
      text-align: center; } }
  .map_banners .title .bold {
    font-weight: bold; }

.map_banners .columns {
  align-items: baseline;
  background-image: linear-gradient(to right, #1e72c5 61%, #ffc81f 61%, #ffc81f 74%, #e70029 74%, #e70029 87%, #00c551 87%);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 3px;
  display: flex; }

.footer {
  background-image: linear-gradient(to right, #1e72c5 61%, #ffc81f 61%, #ffc81f 74%, #e70029 74%, #e70029 87%, #00c551 87%);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 1170px 3px;
  color: #818285;
  padding-top: 1px; }
  @media (max-width: 767px), (min-width: 768px) and (max-width: 1199px) {
    .footer {
      background-size: calc(100% - 30px) 5px;
      top: 192px; } }
  .footer > .container {
    margin-bottom: 50px;
    margin-top: 50px; }
  .footer h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25rem;
    margin-top: 0; }
  .footer p {
    font-size: .875rem;
    line-height: 1.25rem; }
  .footer a {
    font-size: .875rem;
    line-height: 1.5rem; }
    .footer a:hover {
      text-decoration: underline; }

.copyright {
  border-top: 1px solid rgba(129, 130, 133, 0.25);
  font-size: .625rem;
  margin-bottom: 0;
  padding: 15px 0 0;
  text-align: center; }
  .copyright__logo img {
    height: 50px; }

@media (min-width: 1200px) {
  .copyright__text, .copyright__logo {
    line-height: 50px; }
  .copyright__logo {
    flex: initial; } }

@media (max-width: 767px), (min-width: 768px) and (max-width: 1199px) {
  footer,
  .copyright {
    text-align: center; }
    footer__logo,
    .copyright__logo {
      margin-top: 20px; } }

.header {
  background-color: rgba(255, 255, 255, 0.75);
  left: 0;
  padding: 13px;
  position: fixed;
  right: 0;
  top: 0;
  transition-duration: 0.5s;
  z-index: 9995; }
  .header:hover {
    background-color: #fff; }
  .header > .container {
    display: grid;
    grid-gap: 15px;
    grid-template-areas: 'logo phone info info' 'logo customer customer customer'; }
    @media (min-width: 1200px) {
      .header > .container {
        grid-template-columns: 1fr 2fr 2fr auto; } }
    @media (max-width: 767px) {
      .header > .container {
        height: 330px;
        padding-left: 5%;
        padding-right: 5%;
        transition-duration: 0.75s; } }
    @media (min-width: 768px) and (max-width: 1199px) {
      .header > .container {
        height: 160px;
        padding-left: 3%;
        padding-right: 3%;
        transition-duration: 0.75s; } }
    @media (min-width: 768px) and (max-width: 1199px) {
      .header > .container {
        grid-template-areas: 'logo phone phone phone' 'logo info info info' 'customer customer customer customer'; } }
    @media (max-width: 767px) {
      .header > .container {
        grid-template-areas: 'logo logo logo ' 'phone phone phone' 'customer customer customer'; } }
  .header__customer, .header__info, .header__phone {
    align-items: center;
    text-transform: uppercase; }
  .header__phone {
    align-items: center;
    display: grid;
    font-size: 0.6rem;
    grid-area: phone;
    grid-auto-flow: column;
    grid-gap: 15px;
    justify-content: flex-start; }
    @media (max-width: 767px) {
      .header__phone {
        font-size: .688rem;
        justify-content: center; } }
  .header__info {
    display: grid;
    font-size: .875rem;
    grid-area: info;
    justify-content: space-between; }
    @media (max-width: 767px) {
      .header__info {
        display: none; } }
    @media (min-width: 1200px) {
      .header__info {
        display: flex;
        justify-content: flex-end; } }
    .header__info ul {
      display: flex;
      justify-content: flex-end;
      width: 100%; }
      @media (min-width: 1200px) {
        .header__info ul {
          display: grid;
          grid-auto-flow: column;
          gap: 30px; } }
      @media (min-width: 768px) and (max-width: 1199px) {
        .header__info ul li {
          margin-right: 15px; } }
  .header__logo {
    align-items: center;
    display: flex;
    grid-area: logo; }
    .header__logo img {
      height: 70px;
      transition-duration: 0.5s; }
    @media (max-width: 767px) {
      .header__logo {
        justify-content: center; } }
  .header__customer {
    grid-area: customer; }
    .header__customer .selecters {
      display: flex;
      justify-content: space-around;
      white-space: nowrap; }
      @media (max-width: 767px) {
        .header__customer .selecters {
          display: block;
          white-space: normal; } }
      .header__customer .selecters div {
        background: url("../images/down.svg") no-repeat right;
        background-position-x: 220px; }
        @media (max-width: 767px), (min-width: 768px) and (max-width: 1199px) {
          .header__customer .selecters div {
            background-position-x: calc(100% - 20px);
            background-position-y: 20px; } }
      .header__customer .selecters select {
        background: transparent;
        border: 1px solid #eee;
        color: #888888;
        font-size: 1rem;
        font-style: italic;
        font-weight: 400;
        height: 40px;
        line-height: 16px;
        margin-right: 25px;
        width: 250px; }
        @media (max-width: 767px), (min-width: 768px) and (max-width: 1199px) {
          .header__customer .selecters select {
            width: 100%; } }
        @media (max-width: 767px) {
          .header__customer .selecters select {
            justify-content: center;
            margin-bottom: 12px; } }
      .header__customer .selecters button {
        width: 58px; }
        @media (max-width: 767px) {
          .header__customer .selecters button {
            font-size: 1.2rem;
            height: 40px;
            width: 100%; } }
        .header__customer .selecters button i {
          color: #fff;
          font-weight: bold; }
  .body--scrolled .header {
    box-shadow: 0 0.0625rem 0.375rem rgba(23, 23, 24, 0.3); }
    .body--scrolled .header > .container {
      grid-template-areas: 'logo customer customer customer'; }
      @media (min-width: 768px) and (max-width: 1199px) {
        .body--scrolled .header > .container {
          grid-template-areas: 'logo logo info info';
          height: 70px; }
          .body--scrolled .header > .container .header__info {
            display: flex; }
          .body--scrolled .header > .container .header__customer,
          .body--scrolled .header > .container .header__phone {
            display: none; } }
      @media (max-width: 767px) {
        .body--scrolled .header > .container {
          grid-template-areas: "logo logo" "search" "search" "search";
          height: 70px; }
          .body--scrolled .header > .container .header__customer {
            display: none; } }
    .body--scrolled .header .header__logo img {
      height: 40px; }
    .body--scrolled .header .header__info,
    .body--scrolled .header .header__phone {
      display: none; }

.col2-set {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 30px; }
  @media (max-width: 767px), (min-width: 768px) and (max-width: 1199px) and (orientation: portrait) {
    .col2-set {
      grid-auto-flow: row; } }

.content p {
  margin-bottom: 15px; }

.messages, .global-site-notice {
  box-shadow: 0 0.0625rem 0.375rem rgba(23, 23, 24, 0.3);
  font-size: .75rem;
  padding: 15px; }

.messages {
  margin-bottom: 15px;
  margin-top: 15px; }

.global-site-notice {
  background-color: #1e72c5;
  bottom: 0;
  color: #fff;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center; }

.onestepcheckout-success-msg,
.success-msg, .onestepcheckout-warning-msg,
.warn-msg, .error-msg,
.onestepcheckout-error-msg {
  font-size: .875rem;
  padding: 5px 15px;
  position: relative; }
  .onestepcheckout-success-msg::before,
  .success-msg::before, .onestepcheckout-warning-msg::before,
  .warn-msg::before, .error-msg::before,
  .onestepcheckout-error-msg::before {
    left: 0;
    position: absolute; }

.onestepcheckout-success-msg,
.success-msg {
  color: #00c551; }
  .onestepcheckout-success-msg::before,
  .success-msg::before {
    content: '✔'; }

.onestepcheckout-warning-msg,
.warn-msg {
  color: #fe771d; }
  .onestepcheckout-warning-msg::before,
  .warn-msg::before {
    content: '⚠'; }

.error-msg,
.onestepcheckout-error-msg {
  color: #e70029; }
  .error-msg::before,
  .onestepcheckout-error-msg::before {
    content: '✖'; }

.newsletter > .wrapper {
  background-color: #eee;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 30px 40px; }
  @media (max-width: 767px) {
    .newsletter > .wrapper {
      flex-direction: column; } }

.newsletter__col-left, .newsletter__col-right {
  line-height: 1.1rem;
  padding-bottom: 5px;
  padding-top: 5px; }

.newsletter__col-left {
  text-transform: uppercase; }
  .newsletter__col-left span {
    font-size: 1.25rem; }
    @media (max-width: 767px) {
      .newsletter__col-left span {
        text-align: center; } }

.newsletter__col-right {
  flex-basis: 100%;
  max-width: 535px; }
  .newsletter__col-right form {
    position: relative; }
    .newsletter__col-right form input {
      line-height: 37px;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%; }
      .newsletter__col-right form input::placeholder {
        font-style: italic; }
    .newsletter__col-right form button {
      position: absolute;
      right: 8px;
      top: 7px; }
    .newsletter__col-right form .validation-advice {
      color: #e70029;
      margin-top: 5px;
      position: absolute;
      top: 100%; }

.products-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); }
  @media (max-width: 767px) {
    .products-grid {
      grid-gap: 15px;
      grid-template-columns: repeat(auto-fill, minmax(120px, 4fr)); } }
  .products-grid .item img {
    width: 100%; }

html {
  font-size: 16px; }
  @media (max-width: 767px) {
    html {
      font-size: 14px; } }

body {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  font-weight: normal; }

.link {
  color: #ffc81f; }
  .link:hover {
    text-decoration: underline; }

.page-title {
  background-image: linear-gradient(to right, #1e72c5 61%, #ffc81f 61%, #ffc81f 74%, #e70029 74%, #e70029 87%, #00c551 87%);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 3px;
  margin-bottom: 15px;
  padding-bottom: 1px; }
  @media (max-width: 767px) {
    .page-title {
      padding-top: 5px; } }
  .page-title h1 {
    font-size: 1.25rem;
    font-weight: normal;
    margin-bottom: 10px;
    margin-top: 0;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .page-title h1 {
        margin-top: 10px; } }
  .page-title.title-buttons {
    display: flow-root; }
    .page-title.title-buttons .button {
      float: right;
      margin-bottom: 15px;
      margin-top: calc(-1.5625rem / 2); }

.magestore-bannerslider.container {
  padding-left: 0;
  padding-right: 0;
  width: 100%; }

.magestore-bannerslider .flexslider {
  border: 0;
  box-shadow: none;
  margin-bottom: 15px; }
  .magestore-bannerslider .flexslider ol.flex-control-nav {
    bottom: 15px; }
    .magestore-bannerslider .flexslider ol.flex-control-nav li a {
      background-color: #fff;
      height: 12px;
      width: 12px; }
      .magestore-bannerslider .flexslider ol.flex-control-nav li a.flex-active {
        background-color: #00c551; }
  .magestore-bannerslider .flexslider ul.flex-direction-nav li a {
    opacity: .8; }
    .magestore-bannerslider .flexslider ul.flex-direction-nav li a.flex-prev {
      left: 30px; }
    .magestore-bannerslider .flexslider ul.flex-direction-nav li a.flex-next {
      right: 30px; }

@media (max-width: 767px), (min-width: 768px) and (max-width: 1199px) {
  .magestore-bannerslider .flexslider ul.flex-direction-nav li a.flex-next {
    right: 15px; }
  .magestore-bannerslider .flexslider ul.flex-direction-nav li a.flex-prev {
    left: 15px; } }

.blue {
  color: #1e72c5; }

@media (min-width: 1200px) {
  .hide-on-desktop,
  .mobile-only {
    display: none !important; } }

@media (max-width: 767px), (min-width: 768px) and (max-width: 1199px) {
  .hide-on-mobile,
  .desktop-only {
    display: none !important; } }
