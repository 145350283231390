@media #{$desktop} {
  .hide-on-desktop,
  .mobile-only {
    display: none !important; // if the element have this class, it need to be respected
  }
}

@media #{$mobile} {
  .hide-on-mobile,
  .desktop-only {
    display: none !important; // if the element have this class, it need to be respected
  }
}
