.magestore-bannerslider {
  &.container {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .flexslider {
    border: 0;
    box-shadow: none;
    margin-bottom: 15px;

    ol.flex-control-nav {
      bottom: 15px;

      li a {
        background-color: $white;
        height: 12px;
        width: 12px;

        &.flex-active {
          background-color: $green;
        }
      }
    }

    ul.flex-direction-nav li a {
      opacity: .8;

      &.flex-prev {
        left: 30px;
      }

      &.flex-next {
        right: 30px;
      }
    }
  }
}

@media #{$mobile} {
  .magestore-bannerslider .flexslider ul.flex-direction-nav li a {
    &.flex-next {
      right: 15px;
    }

    &.flex-prev {
      left: 15px;
    }
  }
}
