.products-grid.owl-carousel {
  position: relative;

  .owl-buttons {
    position: absolute;
    right: 0;
    text-align: center;
    top: -4rem;

    .owl-next,
    .owl-prev {
      background-color: darken($grey, 30%);
      border-radius: 50%;
      color: $white;
      display: inline-block;
      font-size: 1rem;
      line-height: 25px;
      transition: color .25s;
      width: 25px;
      height: 25px;

      &:hover {
        background-color: $green;
      }
    }

    .owl-prev {
      margin-right: 2px;
    }

    .owl-next {
      margin-left: 2px;
    }
  }

  .owl-item {
    margin-bottom: 15px;
    padding-left: 1px;
    padding-right: 5px;
  }
}
