.header {
  background-color: fade-out($white, .25);
  left: 0;
  padding: 13px;
  position: fixed;
  right: 0;
  top: 0;
  transition-duration: $transition-duration;
  z-index: 9995;

  &:hover {
    background-color: $white;
  }

  > .container {
    display: grid;
    grid-gap: 15px;
    grid-template-areas: 'logo phone info info' 'logo customer customer customer';

    @media #{$desktop} {
      grid-template-columns: 1fr 2fr 2fr auto;
    }

    @media #{$smartphone} {
      height: 330px;
      padding-left: 5%;
      padding-right: 5%;
      transition-duration: 1.5 * $transition-duration;
    }

    @media #{$tablet} {
      height: 160px;
      padding-left: 3%;
      padding-right: 3%;
      transition-duration: 1.5 * $transition-duration;
    }

    @media #{$tablet} {
      grid-template-areas: 'logo phone phone phone' 'logo info info info' 'customer customer customer customer';
    }

    @media #{$smartphone} {
      grid-template-areas: 'logo logo logo ' 'phone phone phone' 'customer customer customer';
    }
  }

  &__customer,
  &__info,
  &__phone {
    align-items: center;
    text-transform: uppercase;
  }

  &__phone {
    align-items: center;
    display: grid;
    font-size: 0.6rem;
    grid-area: phone;
    grid-auto-flow: column;
    grid-gap: 15px;
    justify-content: flex-start;

    @media #{$smartphone} {
      font-size: .688rem;
      justify-content: center;
    }
  }

  &__info {
    display: grid;
    font-size: .875rem;
    grid-area: info;
    justify-content: space-between;

    @media #{$smartphone} {
      display: none;
    }

    @media #{$desktop} {
      display: flex;
      justify-content: flex-end;
    }

    ul {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      @media #{$desktop} {
        display: grid;
        grid-auto-flow: column;
        gap: 30px;
      }

      li {
        @media #{$tablet} {
          margin-right: 15px;
        }
      }
    }
  }

  &__logo {
    align-items: center;
    display: flex;
    grid-area: logo;

    img {
      height: 70px;
      transition-duration: $transition-duration;
    }

    @media #{$smartphone} {
      justify-content: center;
    }
  }

  &__customer {
    grid-area: customer;

    .selecters {
      display: flex;
      justify-content: space-around;
      white-space: nowrap;

      @media #{$smartphone} {
        display: block;
        white-space: normal;
      }

      div {
        background: url('../images/down.svg') no-repeat right;
        background-position-x: 220px;

        @media #{$mobile} {
          background-position-x: calc(100% - 20px);
          background-position-y: 20px;
        }
      }

      select {
        background: transparent;
        border: 1px solid $grey;
        color: darken($grey, 40%);
        font-size: 1rem;
        font-style: italic;
        font-weight: 400;
        height: 40px;
        line-height: 16px;
        margin-right: 25px;
        width: 250px;

        @media #{$mobile} {
          width: 100%;
        }

        @media #{$smartphone} {
          justify-content: center;
          margin-bottom: 12px;
        }
      }

      button {
        width: 58px;

        @media #{$smartphone} {
          font-size: 1.2rem;
          height: 40px;
          width: 100%;
        }

        i {
          color: $white;
          font-weight: bold;
        }
      }
    }
  }

  .body--scrolled & {
    box-shadow: $box-shadow-bottom;

    > .container {
      grid-template-areas: 'logo customer customer customer';

      @media #{$tablet} {
        grid-template-areas: 'logo logo info info';
        height: 70px;

        .header__info {
          display: flex;
        }

        .header__customer,
        .header__phone {
          display: none;
        }
      }

      @media #{$smartphone} {
        grid-template-areas: 'logo logo' 'search''search''search';
        height: 70px;

        .header__customer {
          display: none;
        }
      }
    }

    .header__logo img {
      height: 40px;
    }

    .header__info,
    .header__phone {
      display: none;
    }
  }
}
