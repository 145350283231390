.map_banners {
  .title {
    color: $blue;
    font-size: 2rem;
    font-weight: 400;

    @media #{$smartphone} {
      text-align: center;
    }

    .bold {
      font-weight: bold;
    }
  }

  .columns {
    align-items: baseline;
    background-image: linear-gradient(
    to right,
    $border-blue 61%,
    $border-yellow 61%,
    $border-yellow 74%,
    $border-red 74%,
    $border-red 87%,
    $border-green 87%
    );
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 3px;
    display: flex;
  }
}
